<template>
  <div class="copyright-sidebar">
    <div
      class="copyright-sidebar-item"
      v-for="(item, index) in sideBar"
      :key="index"
      @click="handleClick(item)"
    >
      <span :class="item.active == true ? 'active' : ''">{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightSideBar",
  data() {
    return {
      sideBar: this.$store.state.copyrightServiceSideBar,
    };
  },
  methods: {
    handleClick(item) {
      if (
        this.$route.matched[1].name == item.name &&
        this.$route.matched[1].name !== "CopyrightPractice"
      )
        return;

      this.$router.push({
        name: item.name,
      });
      this.$store.commit("changeCopyrightServiceSideBar", {
        item,
      });
    },
  },
  created() {
    this.sideBar.forEach((item) => {
      if (this.$route.matched[1].name == item.name) {
        this.$store.commit("changeCopyrightServiceSideBar", { item });
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.copyright-sidebar {
  @include flex-center(column);
  padding: 20px 15px;
  &-item {
    @include flex-center;
    width: 100px;
    height: 37px;
    border: 1px solid #ff6900;
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    line-height: 37px;
    &:first-child ~ & {
      margin-top: 30px;
    }
    span {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
    }
    .active {
      color: #ff6900;
    }
  }

  &-item:hover {
    span {
      color: #ff6900;
    }
  }
}
</style>
